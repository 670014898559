<template>
  <v-app>

    <!-- 产品插件 -->
    <v-card :flat="true" class="warps warp2">
      <div class="titleAndImg">
        <div>
          <img :src="form.imgUrl" alt="">
          <div class="titleStyle">
            <span class="title">{{form.title}}</span>
            <span class="tip">{{form.tip}}</span>
          </div>
        </div>
      </div>
      <v-container>
        <v-row class="max-width-1200 " style="margin:0 auto" >
          <v-col cols="12" >
            <v-card flat>
              <div class="img_title" justify="center">
                <div class="imgBox rounded-lg" >
                  <vue-qr :correctLevel="1" :margin="10"
                             :size="150"
                             :text="form.downloadUrl"
                             background-color="#f5f7fa"
                           ></vue-qr>
                </div>
                <v-btn class="buttonStyle rounded-pill" height="40" width="278" @click="ApClick">
                  <span  class="font-size-14">安卓应用下载</span>
                </v-btn>
                <p>ios平台即将上线，敬请期待</p>
              </div>
            </v-card>
          </v-col>
        </v-row>
      </v-container>
    </v-card>

  </v-app>
</template>

<script>
import VueQr from "vue-qr";

export default {
  name: 'Home',
  components: { VueQr },
  data() {
    return {
      data:{
        cyxcs:{
          imgUrl:'https://h5.ophyer.cn/official_website/other/cycs.png',
          title:'成语小测试',
          tip:'提升您的成语水平',
          downloadUrl:'https://material.ophyer.cn/apk/成语小测验-release.apk'
        },
        jwxf:{
          imgUrl:'https://h5.ophyer.cn/official_website/other/jwxf.png',
          title:'箭无虚发',
          tip:'超神射手，箭无虚发',
          downloadUrl:'https://material.ophyer.cn/apk/箭无虚发-release.apk'
        },
        xcxs:{
          imgUrl:'https://h5.ophyer.cn/official_website/other/xcxs.png',
          title:'星辰小说',
          tip:'享受每一分钟阅读',
          downloadUrl:'https://material.ophyer.cn/apk/星辰小说20220318.apk'
        },
        yyxs:{
          imgUrl:'https://h5.ophyer.cn/official_website/other/yyxs.png',
          title:'云游小说',
          tip:'海量小说，任你畅游',
          downloadUrl:'https://material.ophyer.cn/apk/云游小说20220314.apk'
        },
        zfxs:{
          imgUrl:'https://h5.ophyer.cn/official_website/other/zfxs.png',
          title:'掌风小说',
          tip:'智能阅读新体验',
          downloadUrl:'https://material.ophyer.cn/apk/掌风小说20220318.apk'
        }
      },
      form:{},
    }
  },
  created() {
  },
  mounted(){
    this.getForm(window.location.href.split('=')[1])
  },
  methods: {
    getForm(keyType){
      this.form = this.data[keyType]
      console.log('this.form',this.form)
    },
    ApClick(){
      window.open(this.form.downloadUrl, '_blank')
    }
  }
}
</script>

<style lang="scss" scoped>
::v-deep .v-card__subtitle, .text-subtitle-1{
  line-height: 20px !important;
  padding:1px 6px 6px 0;
}


.warps {
  margin: 50px auto 0;
  text-align: center;
  width: 1200px !important;
}


.warp2 {
  .titleAndImg{
    img{
      width:90px;
      height: 90px;
      margin-bottom:15px;
    }
    .titleStyle{
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      .title{
        font-size: 18px;
        font-weight: 600;
        color: #303234;
      }
      .tip{
        font-size: 14px;
        margin:18px 0 56px 0;
        color: #606469;
      }
    }
  }
  .img_title {
    width: 266px;
    margin: 0 auto;
    text-align: center;
    .imgBox{
      width:186px;
      height:186px;
      margin: 0 auto;
      border: 1px solid #E8E9EC;
      img {
        margin: 11px auto;
        width: 164px ;
        height: 164px;
      }
    }
    .buttonStyle{
      margin-top:26px;
      background: linear-gradient(90deg, #5CBDFF 0%, #2F88FF 100%);
      box-shadow: 0px 2px 5px 0px rgba(42, 118, 221, 0.24);
      span {
        color: #FFFFFF;
      }
    }
    p {
      font-size: 14px;
      color: #606469;
      margin-top: 20px;
    }
  }
}

</style>
